<template>
  <div class="fragment">
    <ExpressOrderEditUser
            v-if="!advancedUser"
            :ex="ex"
            :selectedCountry="ex.express.delivery.delivery.country"
            :loadedProformOptions="loadedProformOptions"
            :hasCreateLabel="hasCreateLabel"
            @addProduct="addProduct"
            @removeProduct="removeProduct"
            @adminSave="updateExpress"
            @createLabel="createLabel"
            @changeHasCreateLabel="changeHasCreateLabel"
            @openOrderNotification="orderNotificationPopup = true; orderNotificationPopupTypeInfo = false"
    />

    <ExpressOrderEditAdvancedUser
            v-else
            :ex="ex"
            :selectedCountry="ex.express.delivery.delivery.country"
            :loadedProformOptions="loadedProformOptions"
            @addProduct="addProduct"
            @removeProduct="removeProduct"
            @adminSave="updateExpress"
            @createLabel="createLabel"
    />

    <AuthorizePaymentPopup
            v-if="authorizePaymentPopup"
            :countMoney="authorizePaySum"
            :authorizePayData="authorizePayData"
            :authorizeError="authorizeError"
            :serverError="serverError"
            :changeCloseToSaveBtn="true"
            @close="()=>{$router.push(this.$store.getters.GET_PATHS.expressOrders)}"
            @submitForm="submitAuthorizeInvoicePay"
    />

    <BraintreePaymentPopup
        v-if="braintreePaymentPopup"
        :amount="authorizePaySum"
        :payData="authorizePayData"
        :invoicePayId="authorizeInvoiceId"
        :changeCloseToSaveBtn="true"
        @submitForm="afterPay"
        @close="()=>{$router.push($store.getters.GET_PATHS.expressOrders)}"
    />

    <ThankYouPopup
            v-if="thankYouPopupPopup"
            @close="() => {$router.push(this.$store.getters.GET_PATHS.expressOrders)}"
            :title="'common_paySuccessTitle'"
            :text="'common_paySuccessText'"
            :linkLastText="'common_paySuccessTextLink'"
            :linkLastTextHref="$store.getters.GET_PATHS.financeMyPayments + '?type=authorize'"
    />

    <OrderNotificationPopup
        v-if="orderNotificationPopup"
        :orderNotificationData="ex.express.orderNotification"
        :typeInfo="orderNotificationPopupTypeInfo"
        :orderType="'order_express'"
        :orderId="ex.express.expressId"
        @close="orderNotificationPopup = false"
        @reload="reloadNotification"
    />

    <NotificationPackingTypePopup
        v-if="notificationPackingTypePopup"
        :type="getPackingType"
        @close="notificationPackingTypePopup = false"
    />

  </div>
</template>

<script>
  import ExpressOrderEditUser from "./ExpressOrderEditUser/ExpressOrderEditUser.vue";
  import {Express} from "../../models/Express";
  import {proformMixins} from "../../../../../mixins/proformMixins/proformMixins";
  import {expressMixinsFromCard} from "../../../../../mixins/expressMixins/expressMixin";
  import AuthorizePaymentPopup from "../../../../coreComponents/Popups/AuthorizePaymentPopup/AuthorizePaymentPopup";
  import ThankYouPopup from "../../../../coreComponents/Popups/ThankYouPopup/ThankYouPopup";
  import {paymentBlockFunc} from "../../../../../mixins/dashboardMixins/paymentBlockFunc";
  import ExpressOrderEditAdvancedUser from "./ExpressOrderEditAdvancedUser/ExpressOrderEditAdvancedUser";
  import OrderNotificationPopup from "@/components/coreComponents/Popups/OrderNotificationPopup/OrderNotificationPopup";
  import {FEDEX_PACKAGING_TYPES} from "@/staticData/staticVariables";
  import NotificationPackingTypePopup
    from "@/components/modules/ExpressOrdersModule/popups/NotificationPackingTypePopup/NotificationPackingTypePopup";
  import BraintreePaymentPopup from "@/components/coreComponents/Popups/BraintreePaymentPopup/BraintreePaymentPopup";

  export default {
    name: "ExpressOrderEdit",
    components: {
      BraintreePaymentPopup,
      NotificationPackingTypePopup,
      OrderNotificationPopup,
      ExpressOrderEditAdvancedUser,
      ThankYouPopup,
      AuthorizePaymentPopup,
      ExpressOrderEditUser,
    },

    mixins: [
      proformMixins,
      expressMixinsFromCard,
      paymentBlockFunc,
    ],

    props: {
      easyOrder: {
        type: Boolean,
        default: false,
      }
    },

    watch: {
      loadUserRoleAndAuthorizedData: function() {
        if(this.ex.express.senderContactName === ''){
          this.ex.setSenderInfo(this.loadUserAuthorizedData.user)
        }
      },

      downloadPermissions() {
        this.checkPermission()
      },

      getOrderNotification(value) {
        if(value && this.isAdmin) {
          this.orderNotificationPopupTypeInfo = true
          this.orderNotificationPopup = true
        }
      },

      getPackingType(value) {
        if(value && this.isAdmin &&
            !this.notificationPackingTypePopupShowing &&
            (FEDEX_PACKAGING_TYPES.ENVELOPE_FEDEX_PACKAGING_TYPE.value === value ||
            FEDEX_PACKAGING_TYPES.FEDEX_PACKAGING_TYPE.value === value)
        ) {
          this.notificationPackingTypePopup = true
          this.notificationPackingTypePopupShowing = true
        }
      },
    },

    computed: {
      getOrderNotification() {
        return this.ex.express.orderNotification?.active === 1
      },

      getPackingType() {
        return this.ex.express.fedexPackingType?.value
      },


    },

    data() {
      return {
        ex: new Express(),
        loadedProformOptions: -1,

        hasCreateLabel: false,

        serverError: false,
        authorizePayData: {},
        authorizePaySum: -1,
        authorizeInvoiceId: -1,
        authorizePaymentPopup: false,
        braintreePaymentPopup: false,
        thankYouPopupPopup: false,
        authorizeError: false,
        advancedUser: false,

        orderNotificationPopup: false,
        orderNotificationPopupTypeInfo: false,

        notificationPackingTypePopup: false,
        notificationPackingTypePopupShowing: false,
      }
    },

    created() {

      if(this.easyOrder) {
        this.ex.express.easyOrderType = true
      }

      if(this.downloadPermissions){
        if(!this.checkPermission()) return
      }

      this.advancedUser = this.isExpertUser

      this.ex.setType(this.$route.params.type)
      this.ex.setId(this.$route.params.id)



      // if (this.$route.params.typeTransaction && this.$route.params.id) {
      //   this.ex.setExpressTypeTransaction({
      //     type: this.$route.params.typeTransaction,
      //     id: this.$route.params.id,
      //   })
      // }

      // initialize first product
      this.initializeProductProformArray(this.ex.express.products).then(() => {

        let dispatch = 'getEditExpress'

        if(this.isEasyType) {
          dispatch = 'getEasyOrdersExpress'
        }

        this.$store.dispatch(dispatch, this.$route.params.id).then((response) => {

          let order = this.getRespData(response)

          // set EXPRESS with all models
          this.ex.setExpressItem(order, this.isAdmin)

          // set EXPRESS image & pdf files
          this.setFiles()

          this.ex.express.products.map((itemProduct, index) => {
            this.initialProformSelectOptions({
              val: itemProduct.product.proformData.proformCategory,
              item: itemProduct
            }).then(() => {
              this.loadedProformOptions = index
            })
          })



          if(this.loadUserAuthorizedData && this.ex.express.senderContactName === ''){

            if(this.isAdmin){
              this.ex.setSenderInfo(this.ex.express.expressObj.expressOrderInstance.user)
            } else {
              this.ex.setSenderInfo(this.loadUserAuthorizedData.user)
            }
          }

        }).catch(error => this.createErrorLog(error))
      }).catch(error => this.createErrorLog(error))

    },

    methods: {

      reloadNotification() {
        this.$store.dispatch('getEditExpress', this.$route.params.id).then(() => {
          this.ex.express.orderNotification = this.ex.express.expressObj?.orderNotification
        })
      },

      submitAuthorizeInvoicePay(data){
        this.payAuthorizeInvoice({data: data, invoiceId: this.authorizeInvoiceId}).then(status => {
          if(status){
            this.$store.dispatch('afterInvoicePayStatusUpdate', this.$route.params.id)
          }
          this.authorizePaymentPopup = false

          setTimeout(() => {
            this.changeThankYouPopupPopup(true)
          }, 200)
        })
      },

      setFiles() {
        if (this.ex.express.expressObj.files) {
          if (this._.has(this.ex.express.expressObj.files, 'pdf')) {
            this.$store.dispatch('getFileFromServer', this.ex.express.expressObj.files.pdf)
              .then((response) => {
                this.ex.setExpressDownloadFiles(response)
              }).catch(error => this.createErrorLog(error))
          }

          if (this._.has(this.ex.express.expressObj.files, 'big_image')) {
            this.$store.dispatch('getFileFromServer', this.ex.express.expressObj.files.big_image)
              .then((response) => {
                this.ex.setExpressDownloadFiles(response)
              }).catch(error => this.createErrorLog(error))
          }


          if (this._.has(this.ex.express.expressObj.files, 'vat_pdf')) {
            this.$store.dispatch('getFileFromServer', this.ex.express.expressObj.files.vat_pdf)
              .then((response) => {
                this.ex.setExpressVatNumberFile(response)
              }).catch(error => this.createErrorLog(error))
          }
        }



      },

      addProduct() {
        let newIndex = this.ex.addProduct()
        this.initializeProductProformArray(this.ex.express.products, newIndex)
      },

      removeProduct(index) {
        this.ex.removeProduct(index)
      },

      createLabel() {
        this.updateExpress({draft: true, createLabel: true})
      },

      checkNewFiles(){
        if(Object.keys(this.ex.express.downloadFiles).length === 0 && this.ex.express.files.length > 0){
          return true
        }
        return false
      },

      upsertExpress(expressId) {
        let adminData = {
          'order_express_id': parseInt(expressId),
          'admin_order_amount': this.ex.express.payedInvoiceNetAmount !== '' ? this.ex.express.payedInvoiceNetAmount : 0,
          'admin_fee_amount': this.ex.express.payedInvoiceFeeAmount !== '' ? this.ex.express.payedInvoiceFeeAmount : 0,
          'admin_invoice_status': this.ex.express.expressInvoiceStatus,
        }
        this.$store.dispatch('upsertExpress', adminData).then(response => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRecordChanged')
              this.$router.push(this.$store.getters.GET_PATHS.expressOrders + this.$store.getters.getExpressFilter)
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },



      // createFiles(expressId) {
      //   let reader = new FormData();
      //   this.ex.express.files.map((item) => {
      //     if (!this._.has(item, 'maxCount') && !item.maxSizeError) {
      //       reader.append("file", item.file);
      //     }
      //   })
      //   reader.append("order_express_id", expressId);
      //
      //   this.$store.dispatch('addPhotoToExpress', reader).then((response) => {
      //     if (this._.has(response, 'data') && response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
      //       this.openNotify('success', 'common_notificationRecordChanged')
      //
      //       this.$router.push(this.$store.getters.GET_PATHS.expressOrders)
      //
      //     } else if (response.response.data.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
      //       alert('novalid')
      //     } else {
      //       this.openNotify('error', 'common_notificationUndefinedError')
      //     }
      //
      //   })
      // },

    },

  }
</script>

<style scoped>

</style>
