<template>
  <div class="order-create__section">
    <div class="order-create__section-label order-create__section-label--with-button section-label"
         v-if="!isAdvanced"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
                        'ex_personalInformation',
                        'common_warehouseMN',
                        'common_askSavedAddress',
                      ])"></div>
      {{$t('ex_personalInformation.localization_value.value')}}
      <MainButton
          v-if="ex.express.warehouse && !(ex.express.warehouse.id === CITY_KIEV.id && ex.express.type === FEDEX_DELIVERY_SERVICE.id)"
          class="ml-3 btn-fit-content"
          :value="$t('common_warehouseMN.localization_value.value')"
          @click.native="openGetMNContactDataPopup"
          v-bind:class="{'disabled-btn' : $store.getters.getLoadingWarehouses}"
      />
    </div>

    <template v-if="!isAdvanced">
      <div class="order-create__row custom-row">
        <div class="order-create__col custom-col custom-col--33 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
                        'express_ContactName',
                      ])"></div>
          <DefaultInput
              :label="$t('express_ContactName.localization_value.value')"
              :type="'text'"
              :name="'name'"
              :autocomplete="'name'"
              v-bind:class="{'ui-no-valid': ex.express.personal.validation.personalName}"
              :errorTxt="serverError ? ex.express.personal.validationTxt.personalName : $t(`${ex.express.personal.validationTranslate.personalName}.localization_value.value`)"
              :error="ex.express.personal.validation.personalName"
              v-model="ex.express.personal.information.personalName"
          />
        </div>
        <div class="order-create__col custom-col custom-col--33 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
                        'common_phone',
                      ])"></div>
          <DefaultInput
              :label="$t('common_phone.localization_value.value')"
              :type="'tel'"
              :autocomplete="'tel'"
              v-bind:class="{'ui-no-valid': ex.express.personal.validation.personalPhone}"
              :errorTxt="serverError ? ex.express.personal.validationTxt.personalPhone : $t(`${ex.express.personal.validationTranslate.personalPhone}.localization_value.value`)"
              :error="ex.express.personal.validation.personalPhone"
              v-model="ex.express.personal.information.personalPhone"
          />
        </div>
        <div class="order-create__col custom-col custom-col--33 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
                        'express_Email',
                      ])"></div>
          <DefaultInput
              :label="$t('express_Email.localization_value.value')"
              :type="'email'"
              :name="'email'"
              :autocomplete="'email'"
              v-bind:class="{'ui-no-valid': ex.express.personal.validation.personalEmail}"
              :errorTxt="serverError ? ex.express.personal.validationTxt.personalEmail : $t(`${ex.express.personal.validationTranslate.personalEmail}.localization_value.value`)"
              :error="ex.express.personal.validation.personalEmail"
              v-model="ex.express.personal.information.personalEmail"
          />
        </div>
      </div>

      <div class="order-create__section-label section-label"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                        'ex_deliveryAddress',
                        'express_FedExDelivery',
                        'express_TNTDelivery',
                        'express_DHLDelivery',
                        'express_FEDEXCannotDeliver',
                        'express_TNTCannotDeliver',
                        'express_DHLCannotDeliver',
                      ])"></div>
        {{$t('ex_deliveryAddress.localization_value.value')}}

        <v-popover
            class="site-tooltip"
            :disabled="!tooltipAddProductActive"
            offset="5"
            placement="top"
            trigger="hover"
        >
          <TooltipBtn/>

          <template slot="popover">
            <p>
              <b>
                <template v-if="deliveryName === 'FEDEX'">{{$t('express_FedExDelivery.localization_value.value')}}
                </template>
                <template v-if="deliveryName === 'TNT'">{{$t('express_TNTDelivery.localization_value.value')}}</template>
                <template v-if="deliveryName === 'DHL'">{{$t('express_DHLDelivery.localization_value.value')}}</template>
              </b>
            </p>
            <p>
              <template v-if="deliveryName === 'FEDEX'">{{$t('express_FEDEXCannotDeliver.localization_value.value')}}
              </template>
              <template v-if="deliveryName === 'TNT'">{{$t('express_TNTCannotDeliver.localization_value.value')}}
              </template>
              <template v-if="deliveryName === 'DHL'">{{$t('express_DHLCannotDeliver.localization_value.value')}}
              </template>
            </p>
            <a v-close-popover class="tooltip__close"></a>
          </template>
        </v-popover>
      </div>


      <div class="order-create__row custom-row">
        <div class="order-create__col custom-col custom-col--33 custom-col--sm-100 mb-4"
             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
                        'common_address',
                        'express_maxSymbols',
                        'error_TNTAddress',
                      ])"></div>
          <DefaultInput
              :label="$t('common_address.localization_value.value')"
              :type="'text'"
              :name="'address'"
              :autocomplete="'address-line1'"
              v-bind:class="{'ui-no-valid': ex.express.delivery.validation.address}"
              :errorTxt="serverError ? ex.express.delivery.validationTxt.address : $t(`${ex.express.delivery.validationTranslate.address}.localization_value.value`)"
              :error="ex.express.delivery.validation.address"
              :disabled="ex.express.isCopy && ex.express.transactionNum.length > 0"
              v-model="ex.express.delivery.delivery.address"
              :maxLength="'35'"
              :caption="$t('express_maxSymbols.localization_value.value')"
          />
        </div>
        <div class="order-create__col custom-col custom-col--33 custom-col--sm-100 mb-4"
             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
                        'common_addressSecond',
                        'express_maxSymbols',
                        'error_TNTAddress',
                      ])"></div>
          <DefaultInput
              :label="$t('common_addressSecond.localization_value.value')"
              :type="'text'"
              :name="'address2'"
              :autocomplete="'address-line2'"
              v-bind:class="{'ui-no-valid': ex.express.delivery.validation.address2}"
              :errorTxt="serverError ? ex.express.delivery.validationTxt.address2 : $t(`${ex.express.delivery.validationTranslate.address2}.localization_value.value`)"
              :error="ex.express.delivery.validation.address2"
              v-model="ex.express.delivery.delivery.address2"
              :disabled="ex.express.isCopy && ex.express.transactionNum.length > 0"
              :maxLength="'35'"
              :caption="$t('express_maxSymbols.localization_value.value')"
          />
        </div>
        <div class="order-create__col custom-col custom-col--33 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
                        'common_city',
                      ])"></div>
          <DefaultInput
              :name="'city'"
              :autocomplete="'address-level2'"
              :label="$t('common_city.localization_value.value')"
              v-bind:class="{'ui-no-valid': ex.express.delivery.validation.city}"
              :errorTxt="serverError ? ex.express.delivery.validationTxt.city : $t(`${ex.express.delivery.validationTranslate.city}.localization_value.value`)"
              :error="ex.express.delivery.validation.city"
              :disabled="ex.express.isCopy && ex.express.transactionNum.length > 0"
              v-model="ex.express.delivery.delivery.city"
          />
        </div>

        <div class="order-create__col custom-col custom-col--33 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
                        'common_country',
                      ])"></div>

          <DefaultSelect
              :options="options"
              :label="$t('common_country.localization_value.value')"
              v-bind:class="{'ui-no-valid': ex.express.delivery.validation.country}"
              :errorTxt="serverError ? ex.express.delivery.validationTxt.country : $t(`${ex.express.delivery.validationTranslate.country}.localization_value.value`)"
              :error="ex.express.delivery.validation.country"
              :selected="ex.express.delivery.delivery.country"
              :optionsLabel="'label'"
              :disabled="ex.express.isCopy === 1 && ex.express.transactionNum.length > 0"
              @change="changeCountry"
          />

          <div class="site-link site-link-alt mt-3 ml-auto wfc	d-flex"
               v-if="ex.express.delivery.delivery.country !== ''"
               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                        'express_ViewCustomLimits',
                      ])"></div>
            <div
                @click="showCustomLimitsPopup(ex.express.delivery.getCountry().id, 'isModalCustomLimitsPopup')"
            >
              {{$t('express_ViewCustomLimits.localization_value.value')}}
            </div>
          </div>

        </div>

        <div class="order-create__col custom-col custom-col--33 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
                        'common_region',
                      ])"></div>
          <template v-if="ex.express.delivery.delivery.country.id !== USA_COUNTRY_ID && ex.express.delivery.delivery.country.id !== CANADA_COUNTRY_ID &&
          ex.express.delivery.delivery.country.id !== AUSTRALIA_ID && ex.express.delivery.delivery.country.id !== MEXICO_COUNTRY_ID">
            <DefaultInput
                :type="'text'"
                :name="'region'"
                :autocomplete="'address-level1'"
                :label="$t('common_region.localization_value.value')"
                v-bind:class="{'ui-no-valid': ex.express.delivery.validation.region}"
                :errorTxt="serverError ? ex.express.delivery.validationTxt.region : $t(`${ex.express.delivery.validationTranslate.region}.localization_value.value`)"
                :error="ex.express.delivery.validation.region"
                :disabled="ex.express.isCopy && ex.express.transactionNum.length > 0"
                v-model="ex.express.delivery.delivery.region"
            />
          </template>
          <template v-else>
            <DefaultSelect
                v-if="ex.express.delivery.delivery.country && ex.getRegions().length > 0"
                :options="ex.getRegions()"
                :label="$t('common_region.localization_value.value')"
                v-bind:class="{'ui-no-valid': ex.express.delivery.validation.region}"
                :errorTxt="serverError ? ex.express.delivery.validationTxt.region : $t(`${ex.express.delivery.validationTranslate.region}.localization_value.value`)"
                :error="ex.express.delivery.validation.region"
                :optionsLabel="'code'"
                :selected="getSelectedRegion()"
                :disabled="ex.express.isCopy === 1 && ex.express.transactionNum.length > 0"
                @change="val => {changeRegion(val)}"
            />
          </template>
        </div>


        <!--<div class="order-create__col custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;sm-100">-->
        <!--<DefaultSelect-->
        <!--:options="optionsDistrict"-->
        <!--:label="'Region'"-->
        <!--v-bind:class="{'ui-no-valid': ex.express.delivery.validation.region}"-->
        <!--:errorTxt="serverError ? ex.express.delivery.validationTxt.region : $t(`${ex.express.delivery.validationTranslate.region}.localization_value.value`)"-->
        <!--:error="ex.express.delivery.validation.region"-->
        <!--:selected="ex.express.delivery.delivery.region"-->
        <!--:optionsLabel="'label'"-->
        <!--@change="changeDistrict"-->
        <!--/>-->

        <!--<span class="site-link site-link-alt mt-3 ml-auto wfc	d-flex">View Custom Limits</span>-->
        <!--</div>-->
        <div class="order-create__col custom-col custom-col--16 custom-col--md-33 custom-col--sm-66"
             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
                        'common_zipCode',
                      ])"></div>
          <DefaultInput
              :type="'text'"
              :name="'zip'"
              :autocomplete="'postal-code'"
              :label="$t('common_zipCode.localization_value.value')"
              v-bind:class="{'ui-no-valid': ex.express.delivery.validation.zip}"
              :errorTxt="serverError ? ex.express.delivery.validationTxt.zip : $t(`${ex.express.delivery.validationTranslate.zip}.localization_value.value`)"
              :error="ex.express.delivery.validation.zip"
              :disabled="ex.express.isCopy && ex.express.transactionNum.length > 0"
              v-model="ex.express.delivery.delivery.zip"
          />
        </div>
      </div>
    </template>



    <div class="custom-row"
         v-if="ex.express.type === DHL_DELIVERY_SERVICE.id &&
         ((isEdit && ex.express.gotOrderPriceFlag) || (!isEdit && ex.express.typeTransaction !== '' && ex.express.typeTransactionId !== '' && ex.express.orderPrice !== ''))"
    >
      <div class="custom-col custom-col--33 custom-col--md-100"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                        'express_orderPrice',
                        'express_orderPriceValidate',
                      ])"></div>
        <DefaultInput
                :label="$t('express_orderPrice.localization_value.value')"
                v-model="ex.express.orderPrice"
                :disabled="parseInt($store.getters.getUserProfile.user_setting.dhl) === 0 && !isAdmin"
        />
<!--        :disabled="parseInt($store.getters.getUserProfile.user_setting.change_order_express_price) === 0-->
<!--        && $store.getters.getIsAdminRights !== 'admin'"-->
      </div>
    </div>

    <template v-if="ex.express.type === TNT_DELIVERY_SERVICE.id && ex.express.sendType === ex.express.sendTypes.sender">
      <div class="custom-row">
        <div class="custom-col"
             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
                        'express_LunchBreak',
                      ])"></div>
          <DefaultCheckbox
                  v-model="ex.express.lunchBreak"
                  :label="$t('express_LunchBreak.localization_value.value')"
                  @click="ex.express.lunchBreak = !ex.express.lunchBreak"
          />
        </div>
      </div>
    </template>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--33 custom-col--sm-100">
        <div class="order-create__section-label section-label"
             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
                        'express_DeliveryDate',
                      ])"></div>
          {{$t('express_DeliveryDate.localization_value.value')}}
        </div>

        <div class="order-create__row custom-row">
          <div class="order-create__col custom-col mb-0"
               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                        'common_date',
                      ])"></div>
            <DatePickerDefault
                    :label="$t('common_date.localization_value.value')"
                    v-bind:class="{'ui-no-valid': ex.validation.deliveryDate}"
                    :errorTxt="serverError ? ex.validationTxt.deliveryDate : $t(`${ex.validationTranslate.deliveryDate}.localization_value.value`)"
                    :error="ex.validation.deliveryDate"
                    :value="ex.express.deliveryDate"
            >
              <template slot="body">
                <date-picker
                        v-model="ex.express.deliveryDate"
                        valueType="format"
                        :format="'MM/DD/YY'"
                        :placeholder="'mm/dd/yy'"
                        :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                        :disabled-date="(date) => getRangeDeliveryDate(date)"
                        :time-picker-options="timePickerOptionsBeforeLunch"
                ></date-picker>
              </template>
            </DatePickerDefault>
                        <!--:disabled-date="(date) => getRangeDeliveryDate(date)"-->
          </div>
        </div>
      </div>

      <template
              v-if="ex.express.type === TNT_DELIVERY_SERVICE.id && ex.express.sendType === ex.express.sendTypes.sender">
        <div class="order-create__col custom-col custom-col--33 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
                        'express_TimeGap',
                        'express_BeforeLunch',
                        'express_From',
                        'express_To',
                      ])"></div>
          <div class="order-create__section-label section-label"
               v-if="!ex.express.lunchBreak"
          >
            {{$t('express_TimeGap.localization_value.value')}}
          </div>
          <div class="order-create__section-label section-label"
               v-if="ex.express.lunchBreak"
          >
            {{$t('express_BeforeLunch.localization_value.value')}}
          </div>

          <div class="order-create__row custom-row  mb-0">
            <div class="order-create__col custom-col custom-col--50">
              <DatePickerDefault
                      :label="$t('express_From.localization_value.value')"
                      :value="ex.express.timeCourierFrom"
                      class="date-picker-time-custom"
                      v-bind:class="{'ui-no-valid': ex.validation.timeCourierFrom}"
                      :errorTxt="serverError ? ex.validationTxt.timeCourierFrom : $t(`${ex.validationTranslate.timeCourierFrom}.localization_value.value`)"
                      :error="ex.validation.timeCourierFrom"
              >
                <template slot="body">
                  <date-picker
                          v-model="ex.express.timeCourierFrom"
                          valueType="format"
                          format="HH:mm"
                          :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                          :time-picker-options="timePickerOptionsBeforeLunch"
                          type="time"
                  ></date-picker>
                </template>
              </DatePickerDefault>
                          <!--:disabled-time="(time) => getCourierTimeBeforeLunch(time)"-->
            </div>
            <div class="order-create__col custom-col  custom-col--50 ">
              <DatePickerDefault
                      :label="$t('express_To.localization_value.value')"
                      :value="ex.express.timeCourierTo"
                      class="date-picker-time-custom"
                      v-bind:class="{
                        'ui-no-valid': ex.validation.timeCourierTo,
                        'spacer': ex.express.lunchBreak
                      }"
                      :errorTxt="serverError ? ex.validationTxt.timeCourierTo : $t(`${ex.validationTranslate.timeCourierTo}.localization_value.value`)"
                      :error="ex.validation.timeCourierTo"
              >
                <template slot="body">
                  <date-picker
                          v-model="ex.express.timeCourierTo"
                          valueType="format"
                          format="HH:mm"
                          :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                          :time-picker-options="timePickerOptionsBeforeLunch"
                          type="time"
                  ></date-picker>
                </template>
              </DatePickerDefault>
                          <!--:disabled-time="(time) => getCourierTimeBeforeLunch(time)"-->
            </div>
          </div>
        </div>
        <div class="order-create__col custom-col custom-col--33 custom-col--sm-100"
             v-if="ex.express.lunchBreak"
             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
                        'express_AfterLunch',
                        'express_From',
                        'express_To',
                      ])"></div>
          <div class="order-create__section-label section-label">
            {{$t('express_AfterLunch.localization_value.value')}}
          </div>

          <div class="order-create__row custom-row  mb-0">
            <div class="order-create__col custom-col custom-col--50">
              <DatePickerDefault
                      :label="$t('express_From.localization_value.value')"
                      :value="ex.express.timeNotAvailableFrom"
                      class="date-picker-time-custom"
                      v-bind:class="{'ui-no-valid': ex.validation.timeNotAvailableFrom}"
                      :errorTxt="serverError ? ex.validationTxt.timeNotAvailableFrom : $t(`${ex.validationTranslate.timeNotAvailableFrom}.localization_value.value`)"
                      :error="ex.validation.timeNotAvailableFrom"
              >
                <template slot="body">
                  <date-picker
                          v-model="ex.express.timeNotAvailableFrom"
                          valueType="format"
                          format="HH:mm"
                          :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                          :time-picker-options="timePickerOptionsAfterLunch"
                          type="time"
                  ></date-picker>
                </template>
              </DatePickerDefault>
                          <!--:disabled-time="(time) => getCourierTimeAfterLunch(time)"-->
            </div>
            <div class="order-create__col custom-col  custom-col--50">
              <DatePickerDefault
                      :label="$t('express_To.localization_value.value')"
                      :value="ex.express.timeNotAvailableTo"
                      class="date-picker-time-custom"
                      v-bind:class="{'ui-no-valid': ex.validation.timeNotAvailableTo}"
                      :errorTxt="serverError ? ex.validationTxt.timeNotAvailableTo : $t(`${ex.validationTranslate.timeNotAvailableTo}.localization_value.value`)"
                      :error="ex.validation.timeNotAvailableTo"
              >
                <template slot="body">
                  <date-picker
                          valueType="format"
                          format="HH:mm"
                          :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                          type="time"
                          :time-picker-options="timePickerOptionsAfterLunch"
                          v-model="ex.express.timeNotAvailableTo"
                  ></date-picker>
                </template>
              </DatePickerDefault>
                          <!--:disabled-time="(time) => getCourierTimeAfterLunch(time)"-->


            </div>
          </div>
        </div>
      </template>



<!--           v-if="ex.express.isVatNumberByCountry"-->
      <div class="order-create__col custom-col mb-0"
      >
        <div class="order-create__section-label section-label"
             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
                        'express_Other',
                      ])"></div>
          {{$t('express_Other.localization_value.value')}}
        </div>

        <div class="order-create__product custom-row mt-3">

          <div class="order-create__product-col custom-col"
               :key="'1' + vatNumberValidate"
               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                'common_vatNumber',
              ])"></div>

            <DefaultCheckbox
                    v-model="ex.express.visibleVatNumber"
                    :label="$t('common_vatNumber.localization_value.value')"
                    @click="ex.express.visibleVatNumber = !ex.express.visibleVatNumber"
            />
          </div>
          <div class="order-create__product-col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100 mb-4"
               v-if="ex.express.visibleVatNumber"
               :key="vatNumberValidate"
               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                'common_vatNumber',
              ])"></div>

            <div class="d-flex align-items-center w-100">
              <div class="vat-prefix mr-1" v-if="ex.express.type === DHL_DELIVERY_SERVICE.id">
                <template v-if="ex.express.delivery.delivery.country.id === UNITED_KINGDOM_ID">TAX</template>
                <template v-else>IM</template>
              </div>
              <DefaultInput
                  class="w-100 type-number"
                  :label="$t('common_vatNumber.localization_value.value')"
                  v-bind:class="{'ui-no-valid': ex.validation.vatNumber}"
                  :error="ex.validation.vatNumber"
                  :errorTxt="serverError ? ex.validationTxt.vatNumber : $t(`${ex.validationTranslate.vatNumber}.localization_value.value`)"
                  :type="ex.express.type === DHL_DELIVERY_SERVICE.id ? 'number' : 'text'"
                  v-model="ex.express.vatNumber"
              />
            </div>

          </div>
<!--          v-if="ex.express.visibleVatNumber"-->
          <template v-if="!(ex.express.type === FEDEX_DELIVERY_SERVICE.id && (ex.express.warehouse && ex.express.warehouse.id !== CITY_KIEV.id))">
            <div class="order-create__col custom-col position-relative"
                 v-if="ex.express.vatNumberFile.data.maxCountFiles - Object.keys(ex.express.vatNumberFile.data.downloadFiles).length > 0"
            >
              <DropZone class="drop-zone-bg"
                        :multiple="false"
                        :parentFiles="ex.express.vatNumberFile.data.files"
                        :maxCount="ex.express.vatNumberFile.data.maxCountFiles - ex.express.vatNumberFile.data.downloadFiles.length"
                        :maxSize="ex.express.vatNumberFile.data.maxSizeFiles"
                        :accept="'application/pdf'"
                        @changeImg="changeVatNumberFile"
              />
              <span class="error-field ml-3" v-if="ex.express.vatNumberFile.validation.files">
                {{$t('common_fileIsRequired.localization_value.value')}}
              </span>
            </div>

            <div class="custom-row product-img-row"
                 v-if="Object.keys(ex.express.vatNumberFile.data.downloadFiles).length > 0"
                 :key="images"
            >
              <div class="custom-col custom-col--16 custom-col--md-50 custom-col--sm-100"
                   v-for="(file, indexFile) in ex.express.vatNumberFile.data.downloadFiles"
                   :key="indexFile"
              >
                <div class="site-document d-flex justify-content-center">
                  <div class="site-document__remove" @click="removeVatNumberDocument(parseInt(_.first(Object.keys(file))))">
                    <CloseIcon/>
                  </div>
                  <div v-if="isEdit" class="site-document__img"
                       @click="openPdf(ex.express.vatNumberFile.data.downloadFiles[0][_.first(Object.keys(_.first(ex.express.vatNumberFile.data.downloadFiles)))])">
                    <img src="/img/common/pdf_file_icon.svg" alt="img">
                  </div>
                  <div v-else class="site-document__img">
                    <img src="/img/common/pdf_file_icon.svg" alt="img">
                  </div>
                </div>
              </div>

            </div>
          </template>

        </div>

      </div>
      <div class="order-create__col custom-col custom-col--33 custom-col--sm-100 mb-0 d-flex flex-column justify-content-end">
        <div class="order-create__row custom-row">
          <div class="custom-col"
               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['express_Currencies'])"></div>
            <DefaultSelect
                    :label="$t('express_Currencies.localization_value.value')"
                    :options="optionsCurrencies"
                    :optionsLabel="'name'"
                    :selected="ex.express.currencies ? ex.express.currencies : optionsCurrencies[0]"
                    :clearable="false"
                    :disabled="!ex.express.visibleHSCode"
                    @change="changeCurrencies"
            />
          </div>
        </div>
      </div>

      <div v-if="ex.express.visibleFreightCost" class="order-create__col custom-col custom-col--33 custom-col--sm-100 d-flex flex-column justify-content-end"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                        'express_FreightCost',
                      ])"></div>
        <DefaultInput
            :label="$t('express_FreightCost.localization_value.value')"
            v-bind:class="{'ui-no-valid': ex.validation.freightCost}"
            :errorTxt="serverError ? ex.validationTxt.freightCost : $t(`${ex.validationTranslate.freightCost}.localization_value.value`)"
            :error="ex.validation.freightCost"
            v-model="ex.express.freightCost"
        />
      </div>



      <template v-if="hideCostProofs &&
      ((ex.express.delivery.delivery.country.id !== USA_COUNTRY_ID) && (ex.express.type === DHL_DELIVERY_SERVICE.id) &&
        ((ex.express.typeTransaction === '' &&
        ex.express.typeTransactionId === '') ||
        ex.express.orderPrice === ''))">
        <div class="order-create__section-label section-label custom-col"
             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
                        'express_CostProofs',
                        'express_ToProof',
                      ])"></div>
          {{$t('express_CostProofs.localization_value.value')}}
          <v-popover
                  v-if="ex.express.type === DHL_DELIVERY_SERVICE.id"
                  class="site-tooltip"
                  :disabled="!tooltipAddProductActive"
                  offset="5"
                  placement="top"
                  trigger="hover"
          >
            <TooltipBtn/>

            <template slot="popover">
              <p>
                {{$t('express_ToProof.localization_value.value')}}
              </p>
              <a v-close-popover class="tooltip__close"></a>
            </template>
          </v-popover>
        </div>

        <div class="custom-col position-relative"
             v-if="ex.express.maxCountFiles - Object.keys(ex.express.downloadFiles).length > 0">
          <DropZone class="drop-zone-bg"
                    :parentFiles="ex.express.files"
                    :maxSize="'3'"
                    :multiple="false"
                    :maxCount="ex.express.maxCountFiles"
                    :accept="'image/x-png,image/jpeg,'"
                    @changeImg="changeImg"
          />
          <span class="error-field ml-3" v-if="ex.validation.files">
						{{$t('common_fileIsRequired.localization_value.value')}}
					</span>
        </div>

        <div class="custom-row product-img-row" v-if="Object.keys(ex.express.downloadFiles).length > 0" :key="images">
          <div class="custom-col custom-col--16 custom-col--md-50 custom-col--sm-100">
            <div class="site-document d-flex justify-content-center"
                 v-if="_.has(this.ex.express.expressObj.files, 'pdf')"
            >
							<span class="site-document__remove" @click="removeDocument(ex.express.files[0].pdf)">
									<CloseIcon/>
								</span>
              <div class="site-document__img">
                <img @click="openPdf(ex.express.downloadFiles[ex.express.files[0].pdf])"
                     src="/img/common/pdf_file_icon.svg" alt="ico">
              </div>
            </div>
            <div class="site-document d-flex justify-content-center"
                 v-else
            >
              <div class="site-document__remove" @click="removeDocument(ex.express.files[0].small_image)">
                <CloseIcon/>
              </div>
              <div class="site-document__img">
                <img :src="'data:image/png;base64,' + ex.express.downloadFiles[ex.express.files[0].big_image]"
                     @click="showSingle(ex.express.files[0].big_image)" alt="img">
              </div>
            </div>
          </div>

        </div>
      </template>


    </div>

    <!-- all props & events -->
    <Lightbox
            escDisabled
            moveDisabled
            :visible="visible"
            :imgs="imgs"
            :index="index"
            @hide="handleHide"
    ></Lightbox>

    <CustomLimitsPopup
            @close="closeCustomLimitsPopup"
            v-if="isModalCustomLimitsPopup"
    />


  </div>
</template>

<script>
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import TooltipBtn from "../../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
  import {VPopover} from 'v-tooltip'
  import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import DatePicker from 'vue2-datepicker'
  import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import {
    CURRENCIES,
    DHL_DELIVERY_SERVICE,
    FEDEX_DELIVERY_SERVICE,
    TNT_DELIVERY_SERVICE, UNITED_KINGDOM_ID, USA_COUNTRY_ID,
    CANADA_COUNTRY_ID, MEXICO_COUNTRY_ID, AUSTRALIA_ID, CITY_KIEV,
  } from "../../../../../../../staticData/staticVariables";
  import DropZone from "../../../../../../coreComponents/DropZone/DropZone";
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import Lightbox from 'vue-easy-lightbox'
  import CustomLimitsPopup from "../../../../../../coreComponents/Popups/CustomLimitsPopup/CustomLimitsPopup";
  import {customLimitsMixin} from "../../../../../../../mixins/commonMixins/customLimitsMixin";
  import {expressCountriesMixin} from "../../../../../../../mixins/expressMixins/expressCountriesMixin";
  import CloseIcon from '../../../../../../../../public/img/modal-group/close-icon.svg?inline'
  import {expressUserContactsMixin} from "@/mixins/expressMixins/expressUserContactsMixin";
  import {expressMixinDeliveryDetails} from "@/mixins/expressMixins/expressMixin";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  // import {BLAINE_WAREHOUSE} from "../../../../../../../staticData/staticVariables";

  export default {
    name: "DeliveryDetails",

    components: {
      MainButton,
      CustomLimitsPopup,
      DefaultCheckbox,
      DropZone,
      DefaultInput,
      TooltipBtn,
      VPopover,
      DefaultSelect,
      DatePicker,
      DatePickerDefault,
      Lightbox,
      CloseIcon,
    },

    mixins: [customLimitsMixin, expressCountriesMixin, expressUserContactsMixin, expressMixinDeliveryDetails],

    props: {
      ex: Object,
      vatNumberValidate: Boolean,
      serverError: {
        type: Boolean,
        default: false,
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
      isAdvanced: {
        type: Boolean,
        default: false,
      },
    },

    computed: {

      deliveryName() {
        switch (this.ex.express.type) {
          case FEDEX_DELIVERY_SERVICE.id:
            return 'FEDEX'
          case TNT_DELIVERY_SERVICE.id:
            return 'TNT'
          case DHL_DELIVERY_SERVICE.id:
            return 'DHL'
          default:
            return false
        }
      },

      visibleHSCode() {
        return this.ex.express.visibleHSCode
      },

      changeRecipientCountry(){
        return this.ex.express.delivery.delivery.country
      },

      checkShipperWarehouse(){
        return this.ex.express.warehouse
      },

      getWarehouse() {
        return this.ex.express.warehouse?.id
      },

    },

    watch: {
      getWarehouse() {
        this.getDeliveryCountries()
      },
      // visibleHSCode() {
      //   this.initCurrencies()
      // },

      changeRecipientCountry(newVal) {
        this.checkCurrencies(newVal, this.isEdit)
      },

      checkShipperWarehouse(){
        if(this.ex.express.warehouse?.id === this.cambridgeId) {
          return this.ex.express.currencies = CURRENCIES.GBP
        } else {
          if(!this.ex.express.currencies?.id) {
            this.ex.express.currencies = this.optionsCurrencies[0]
          }
        }
      },
    },


    data() {
      return {
        hideCostProofs: false,
        options: [{}],
        optionsDistrict: [{}],

        images: -1,

        imgs: [], // Img Url , string or Array of string
        visible: false,
        index: 0, // default: 0

        FEDEX_DELIVERY_SERVICE: FEDEX_DELIVERY_SERVICE,
        TNT_DELIVERY_SERVICE: TNT_DELIVERY_SERVICE,
        DHL_DELIVERY_SERVICE: DHL_DELIVERY_SERVICE,

        USA_COUNTRY_ID: USA_COUNTRY_ID,
        CANADA_COUNTRY_ID: CANADA_COUNTRY_ID,
        AUSTRALIA_ID: AUSTRALIA_ID,
        MEXICO_COUNTRY_ID: MEXICO_COUNTRY_ID,
        UNITED_KINGDOM_ID: UNITED_KINGDOM_ID,

        CITY_KIEV: CITY_KIEV,

        isHovering: false,
        tooltipAddProductActive: true,



        timePickerOptionsBeforeLunch:{
          start: '09:00',
          step: '00:15',
          end: '13:00'
        },

        timePickerOptionsAfterLunch:{
          start: '13:00',
          step: '00:15',
          end: '16:00'
        },

        lang: {
          formatLocale: {
            firstDayOfWeek: 1,
            weekdaysMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
          },
          monthBeforeYear: false,
          months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        },
        isModalCustomLimitsPopup: false,

        optionsCurrencies: [
          CURRENCIES.USD,
          CURRENCIES.EUR,
          CURRENCIES.GBP,
        ],

        cambridgeId: 16,
      }
    },

    mounted() {
      this.getDeliveryCountries()

      // this.initCurrencies()
      this.checkCurrencies(this.ex.express.delivery.delivery.country, this.isEdit)
    },

    methods: {


      initCurrencies() {
        if(this.ex.express.expressId === '' && this.isEdit) return

        if(!this.ex.express.currencies?.id){
          if(this.ex.express.warehouse?.id === this.cambridgeId) {
            return this.ex.express.currencies = CURRENCIES.GBP
          }
          this.ex.express.currencies = this.optionsCurrencies[0]
        }

      },

      changeCurrencies(val) {
        this.ex.express.currencies = val
      },

      // getRangeDeliveryDate(date) {
      //
      //   // warehouse?.id === 11 - riga
      //   // warehouse?.id === 13 - lodz
      //   // warehouse?.id === 16 - cambridge
      //   if(
      //       this.ex.express.type === FEDEX_DELIVERY_SERVICE.id &&
      //       (this.ex.express.sendType === this.ex.express.sendTypes.warehouse && this.ex.express.warehouse?.country?.id === UKRAINIAN_COUNTRY_ID ||
      //           this.ex.express.sendType === this.ex.express.sendTypes.sender && this.getUserContactsBySelectedUser?.country?.id === UKRAINIAN_COUNTRY_ID)
      //       // !(this.ex.express.sendType === this.ex.express.sendTypes.warehouse && this.ex.express.warehouse?.id === 11) &&
      //       // !(this.ex.express.sendType === this.ex.express.sendTypes.warehouse && this.ex.express.warehouse?.id === 16) &&
      //       // !(this.ex.express.sendType === this.ex.express.sendTypes.warehouse && this.ex.express.warehouse?.id === 13)
      //   )
      //   {
      //     return (date < new Date().setDate(new Date().getDate() + 3) &&
      //       date > new Date().setDate(new Date().getDate() - 1000))
      //   }
      //   return false
      // },

      getCourierTimeBeforeLunch(time) {
        let date = new Date()
        if (!this.ex.express.lunchBreak) {
          return (time < new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9, 0) ||
            time > new Date(date.getFullYear(), date.getMonth(), date.getDate(), 16, 0));
        }
        return (time < new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9, 0) ||
          time > new Date(date.getFullYear(), date.getMonth(), date.getDate(), 13, 0));
      },
      getCourierTimeAfterLunch(time) {
        let date = new Date()
        if (!this.ex.express.lunchBreak) {
          return (time < new Date(date.getFullYear(), date.getMonth(), date.getDate(), 9, 0) ||
            time > new Date(date.getFullYear(), date.getMonth(), date.getDate(), 16, 0));
        }
        return (time < new Date(date.getFullYear(), date.getMonth(), date.getDate(), 13, 0) ||
          time > new Date(date.getFullYear(), date.getMonth(), date.getDate(), 16, 0));
      },

      changeCountry(val) {
        if (val === '' && this.isEdit) return this.optionsDistrict = ''

        if (val === '') {
          this.ex.express.delivery.setCountry('')
          return
        }
        this.ex.express.delivery.setCountry(val)

        /**
         * Change Currencies after Country
         */
        // if(val && val.id === UNITED_KINGDOM_ID){
        //   this.optionsCurrencies = [
        //     CURRENCIES.GBP,
        //   ]
        // } else {
        //   this.optionsCurrencies = [
        //     CURRENCIES.USD,
        //     CURRENCIES.EUR,
        //   ]
        // }

        // this.changeCurrencies('')
        // this.initCurrencies()
      },

      showSingle(id) {
        this.$store.dispatch('getFileFromServer', id).then((response) => {
          this.imgs = []
          this.imgs.push({
            title: 'img',
            src: `data:image/png;base64,` + response[id]
          })
          this.show()
        }).catch(error => this.createErrorLog(error))
      },

      changeImg(files, maxSizeError) {
        this.ex.express.filesMaxSizeError = maxSizeError
        this.ex.express.files = files
      },

      removeDocument(id) {
        this.$store.dispatch('removeUserDocument', id).then(() => {
          this.ex.express.downloadFiles = ''
          this.ex.express.files = []
          this.images = id
        })
      },

      handleHide() {
        this.visible = false
      },

      show() {
        this.visible = true
      },

      openPdf(base64) {
        this.globalOpenPdfBase64(base64)
      },

      changeVatNumberFile(files, maxSizeError) {
        this.ex.express.vatNumberFile.setFiles(files)

        this.ex.express.vatNumberFile.setFilesMaxSizeError(maxSizeError)
      },

      removeVatNumberDocument(id) {
        this.$store.dispatch('removeUserDocument', id).then(() => {
          this.ex.removeVatNumberFile(id - 1)
          this.images = id
        })
      },


      closeCustomLimitsPopup() {
        this.isModalCustomLimitsPopup = false
      },

      changeRegion(val) {
        this.ex.express.delivery.setRegion(val?.code)
      },

      getSelectedRegion() {
        if(!this.ex.express.delivery.getRegion()) {
          return ''
        }
        return this.ex.getRegions().find(item => {
          return item?.code.toLowerCase() === this.ex.express.delivery.getRegion().toLowerCase()
        })
      },


    },

  }
</script>

<style scoped>
.site-document {
  width: 115px;
}

.type-number >>> input::-webkit-outer-spin-button,
.type-number >>> input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.type-number >>> input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}

.vat-prefix {
  line-height: 43px;
  /*color: red;*/
}
</style>
