<template>
  <div class="fragment">
    <template v-if="$store.getters.getExpress.length > 0 && !$store.getters.getExpressLoading">
      <div class="site-table-wrap products-table express-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'express_order',
          'express_receiver',
          'express_Created',
          'ex_sendFrom',
          'express_Country',
          'express_Ship',
          'express_TrackingNumberCDEKNP',
          'express_TrackingNumber',
          'express_DeliveryDate',
          'express_ItemName',
          'common_manage',
          'common_edit',
          'common_delete',
          'common_copy',
          'common_Reference',
          'common_CreateLabel',
          'common_ConfirmLabel',
          'express_BuyLabel',
          'common_DocsDHL',
          'common_DocsFedEx',
          'common_Proforma',
          'common_CallPickup',
          'common_CancelCourier',
          'express_novaPoshta',
          'express_novaPoshtaEdit',
          'express_novaPoshtaShow',
          'poshta_CreateNovaPoshtaLabel',
          'poshta_Label',
          'poshta_Marking',
          'express_CDEKCreate',
          'express_CDEKEdit',
          'express_CDEKShow',
          'express_CDEKCreateLabel',
          'express_CDEKWaybill',
          'express_CDEKBarcode',
          'common_GenerateCertificateOfOrigin',
          'common_DropBallToxicSubstance',
          'common_ToxicSubstanceControlActCertification',
          'express_alarmStatus',
          'express_alarm',
        ])"></div>
        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th></th>
            <th> {{ $t('express_order.localization_value.value') }}</th>
            <th>{{ $t('express_receiver.localization_value.value') }}</th>
            <th> {{ $t('express_Created.localization_value.value') }}</th>
            <th v-if="isAdmin"> {{ $t('common_user.localization_value.value')}}</th>
            <th> {{ $t('ex_sendFrom.localization_value.value') }}</th>
            <th> {{ $t('express_Country.localization_value.value') }}</th>
            <th class="white-space-line">{{ $t('express_Ship.localization_value.value') }}</th>
            <th>
              <template v-if="(!isBelarus && !isSNGGroup && !isLatvian) && !isAdmin">
                <img src="/img/company-icons-group/novaposhta-logo-thead.png" alt="ico" width="20" height="19">
<!--                {{$t('express_TrackingNumberNP.localization_value.value')}}-->
              </template>

              <template v-if="(isBelarus || isSNGGroup) && !isAdmin">
<!--                <img src="/img/company-icons-group/cdek.png" alt="ico" width="20" height="19">-->
<!--                {{$t('express_TrackingNumberCDEK.localization_value.value')}}-->
              </template>

<!--              <template v-if="isAdmin">-->
              {{$t('express_TrackingNumberCDEKNP.localization_value.value')}}
<!--              </template>-->

            </th>
            <th>{{$t('express_TrackingNumber.localization_value.value')}}</th>
            <th>{{$t('express_DeliveryDate.localization_value.value')}}</th>
            <th v-if="isAdmin">{{$t('express_alarmStatus.localization_value.value')}}</th>
            <th>{{$t('express_ItemName.localization_value.value')}}</th>
            <th>{{$t('common_manage.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>

          <tr v-bind:class="{
            'site-table__draft-row': item.in_draft === 1,
            'site-table__wait-row': item.status === EXPRESS_DELIVERY_STATUSES.WAIT_FOR_PAY_STATUS,
            'site-table__blue-row': waitingMakingLabelStatus(item.status)
          }"
              v-for="(item, index) in $store.getters.getExpress"
              :key="index">
            <td>
              <DefaultCheckbox
                      class="empty-label"
                      :selectedNow="selectedNow"
                      :dataValue="item.id"
              />
            </td>
            <td>
              <div class="d-flex align-items-center">
                <span class="table-ico mr-2" v-if="navTabs.all.active">
                  <img
                          v-if="TNT_DELIVERY_SERVICE.id == item.delivery_service_id"
                          src="/img/company-icons-group/tab-tnt.svg" alt="ico">
                  <img
                          v-if="DHL_DELIVERY_SERVICE.id == item.delivery_service_id"
                          src="/img/company-icons-group/tab-dhl.svg" alt="ico">
                  <img
                          v-if="FEDEX_DELIVERY_SERVICE.id == item.delivery_service_id"
                          src="/img/company-icons-group/tab-fedex.svg" alt="ico">
                  <img
                          v-if="NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE.id == item.delivery_service_id"
                          src="/img/company-icons-group/logo-poshta-global-table.svg" alt="ico">
                </span>
                <router-link
                    v-if="getExpressTypeById(item.delivery_service_id)"
                        :to="$store.getters.GET_PATHS.expressOrderShow + '/' +
												getExpressTypeById(item.delivery_service_id).name + '/' + item.id"
                        class="table-link btn-style">
                  #{{item.id}}
                </router-link>
                <span class="table-ico ml-2 w16">
                  <LabelCircle v-if="item.payment_transaction_id"
                               :color="'green'" :value="'T'" />
                  <LabelCircle v-else
                               :color="'gray-light'" :value="'N'" />
                </span>
              </div>
            </td>
            <td>
              <TooltipTextHelper>
                <template slot="text">
                  <div class="table-link btn-style ellipsis">
                    {{item.recipient_contact_name}}
                  </div>
                </template>
                <template slot="title">
                  {{ item.recipient_contact_name }}
                </template>
              </TooltipTextHelper>
            </td>
            <td>{{ item.created_at | moment("DD MMM, YYYY") }}</td>

            <td v-if="isAdmin">
              <TableUserColumn
                      v-if="isAdmin"
                      :item="item"
              />
            </td>
            <td>
              <template v-if="!Array.isArray(item.warehouse)">
                <ValueHelper
                    :value="item.warehouse"
                    :deep="'sender_city'"
                />
              </template>
              <template v-else>{{$t('ex_sender.localization_value.value')}}</template>
            </td>
            <td>
              <ValueHelper
                      :value="item.recipient_country"
                      :deep="'name'"
              />
            </td>


            <td>
              <ValueHelper
                  v-if="item.payed_invoice"
                  :value="item.payed_invoice.total_cost_amount ? item.payed_invoice.total_cost_amount : item.payed_invoice.total_amount"
              />
              <template v-else>—</template>
            </td>

            <td>
              <div class="table-row">
                <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <span class="admin-edit"
                        @click="editTranslate(['importO_trackingNumber', 'importO_addNumber'])"></span>
                  <template v-if="item.new_post_tracking_number !== null && item.new_post_tracking_number !== ''">
                    <!--<span>{{item.tracking_number}}</span>-->
                    <div class="d-flex align-items-center">
                      <TooltipTextHelper>
                        <template slot="text">
                          <div class="table-link btn-style ellipsis">
                            {{item.new_post_tracking_number}}
                          </div>
                        </template>
                        <template slot="title">
                          <p>{{item.new_post_tracking_number}}</p>
                        </template>
                      </TooltipTextHelper>

<!--                      <v-popover-->
<!--                          class="site-tooltip"-->
<!--                          offset="5"-->
<!--                          placement="top"-->
<!--                          trigger="hover"-->
<!--                      >-->
<!--                        <div class="table-link btn-style ellipsis">-->
<!--                          {{item.new_post_tracking_number}}-->
<!--                        </div>-->
<!--                        <template slot="popover">-->
<!--                          <p>{{ item.new_post_tracking_number }}</p>-->
<!--                        </template>-->
<!--                      </v-popover>-->
                      <div class="table-ico">
                        <!--v-if="item.tracking_sent === 0"-->
                        <v-popover
                            class="site-tooltip"
                            offset="5"
                            placement="top-left"
                            @show="hideEditField = true"
                            @hide="hideEditField = false"
                        >
                        <span class="site-link">
                            <LinkButton
                                :type="'edit'"
                            />
                        </span>
                          <template slot="popover">
                            <div class="section-label mb-2">{{ $t('importO_trackingNumber.localization_value.value') }}
                            </div>
                            <InputEditField
                                :value="item.new_post_tracking_number"
                                :propId="item.id"
                                :hideEditField="hideEditField"
                                :disabledBtn="$store.getters.getExpressBtn"
                                @onChange="changeNovaPoshtaTrackingNumberExpress"
                            />
                            <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                          </template>
                        </v-popover>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="">
                      <v-popover
                              class="site-tooltip"
                              offset="5"
                              placement="top-left"
                              @show="hideEditField = true"
                              @hide="hideEditField = false"
                      >
                        <span class="table-link btn-style">{{ $t('importO_addNumber.localization_value.value') }}</span>

                        <template slot="popover">
                          <div class="section-label mb-2">{{ $t('importO_trackingNumber.localization_value.value') }}
                          </div>
                          <InputEditField
                                  :value="''"
                                  :propId="item.id"
                                  :hideEditField="hideEditField"
                                  :disabledBtn="$store.getters.getExpressBtn"
                                  @onChange="changeNovaPoshtaTrackingNumberExpress"
                          />

                          <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                        </template>
                      </v-popover>
                    </div>
                  </template>
                </div>
              </div>
            </td>

            <td>

              <template v-if="isCurrentUserRoleUser">
                <div class="d-flex">
                  <TableTrackingLog
                      v-if="item.delivery_state.delivery_status"
                      :trackingLogItem="null"
                      :trackingStateEvent="item.delivery_state"
                  />

                  <TooltipTextHelper>
                    <template slot="text">
                      <div class="ellipsis">
                        <ValueHelper
                            v-if="TNT_DELIVERY_SERVICE.id == item.delivery_service_id"
                            :value="item.tracking_number"
                            :fullValue="'GE'+item.tracking_number+'UA'"
                        />
                        <ValueHelper
                            v-else
                            style="padding-top: 1px"
                            :value="item.tracking_number"
                        />
                      </div>
                    </template>
                    <template slot="title">
                      <ValueHelper
                          v-if="TNT_DELIVERY_SERVICE.id == item.delivery_service_id"
                          :value="item.tracking_number"
                          :fullValue="'GE'+item.tracking_number+'UA'"
                      />
                      <ValueHelper
                          v-else
                          style="padding-top: 1px"
                          :value="item.tracking_number"
                      />
                    </template>
                  </TooltipTextHelper>

<!--                  <v-popover-->
<!--                      class="site-tooltip"-->
<!--                      offset="5"-->
<!--                      placement="top"-->
<!--                      trigger="hover"-->
<!--                  >-->
<!--                    <div class="ellipsis">-->
<!--                      <ValueHelper-->
<!--                          v-if="TNT_DELIVERY_SERVICE.id == item.delivery_service_id"-->
<!--                          :value="item.tracking_number"-->
<!--                          :fullValue="'GE'+item.tracking_number+'UA'"-->
<!--                      />-->
<!--                      <ValueHelper-->
<!--                          v-else-->
<!--                          style="padding-top: 1px"-->
<!--                          :value="item.tracking_number"-->
<!--                      />-->
<!--                    </div>-->
<!--                    <template slot="popover">-->
<!--                      <p>-->
<!--                        <ValueHelper-->
<!--                            v-if="TNT_DELIVERY_SERVICE.id == item.delivery_service_id"-->
<!--                            :value="item.tracking_number"-->
<!--                            :fullValue="'GE'+item.tracking_number+'UA'"-->
<!--                        />-->
<!--                        <ValueHelper-->
<!--                            v-else-->
<!--                            style="padding-top: 1px"-->
<!--                            :value="item.tracking_number"-->
<!--                        />-->
<!--                      </p>-->
<!--                    </template>-->
<!--                  </v-popover>-->

                </div>
              </template>

              <div class="table-row"
                   v-else
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit"
                      @click="editTranslate(['importO_trackingNumber', 'importO_addNumber'])"></span>
                <template v-if="item.tracking_number !== null && item.tracking_number !== ''">
                  <div class="table-link  d-flex align-items-center">
<!--                    <div v-if="item.status == EXPRESS_DELIVERY_STATUSES.COMPLETED_STATUS">-->
<!--                      <StatusIcoBtn class="mr-1" :type="'delivered'"/>-->
<!--                    </div>-->
<!--                    <div v-if="item.status == EXPRESS_DELIVERY_STATUSES.IN_PROGRESS_STATUS">-->
<!--                      <StatusIcoBtn class="mr-1" :type="'in-transit'"/>-->
<!--                    </div>-->
<!--                    <TableTrackingLog-->
<!--                        :trackingLogItem="item.tracking_log"-->
<!--                    />-->

                    <TableTrackingLog
                        v-if="item.delivery_state.delivery_status"
                        :trackingLogItem="null"
                        :trackingStateEvent="item.delivery_state"
                    />

                    <TooltipTextHelper>
                      <template slot="text">
                        <div class="table-link btn-style ellipsis">
                          <template v-if="TNT_DELIVERY_SERVICE.id == item.delivery_service_id">
                            GE</template>{{item.tracking_number}}<template v-if="TNT_DELIVERY_SERVICE.id == item.delivery_service_id">UA
                          </template>
                        </div>
                      </template>
                      <template slot="title">
                        <template v-if="TNT_DELIVERY_SERVICE.id == item.delivery_service_id">
                          GE</template>{{item.tracking_number}}<template v-if="TNT_DELIVERY_SERVICE.id == item.delivery_service_id">UA
                        </template>
                      </template>
                    </TooltipTextHelper>

<!--                    <v-popover-->
<!--                        class="site-tooltip"-->
<!--                        offset="5"-->
<!--                        placement="top"-->
<!--                        trigger="hover"-->
<!--                    >-->
<!--                      <div class="table-link btn-style ellipsis">-->
<!--                        <template v-if="TNT_DELIVERY_SERVICE.id == item.delivery_service_id">-->
<!--                        GE</template>{{item.tracking_number}}<template v-if="TNT_DELIVERY_SERVICE.id == item.delivery_service_id">UA-->
<!--                        </template>-->
<!--                      </div>-->
<!--                      <template slot="popover">-->
<!--                        <p>-->
<!--                          <template v-if="TNT_DELIVERY_SERVICE.id == item.delivery_service_id">-->
<!--                            GE</template>{{item.tracking_number}}<template v-if="TNT_DELIVERY_SERVICE.id == item.delivery_service_id">UA-->
<!--                          </template>-->
<!--                        </p>-->
<!--                      </template>-->
<!--                    </v-popover>-->
                  </div>
                  <div class="table-ico">
                    <!--v-if="item.tracking_sent === 0"-->
                    <v-popover
                            class="site-tooltip"
                            offset="5"
                            placement="top-left"
                            @show="hideEditField = true"
                            @hide="hideEditField = false"
                    >
                    <span class="site-link">
                        <LinkButton
                                :type="'edit'"
                        />
                    </span>
                      <template slot="popover">
                        <div class="section-label mb-2">{{ $t('importO_trackingNumber.localization_value.value') }}
                        </div>
                        <InputEditField
                                :value="item.tracking_number"
                                :propId="item.id"
                                :hideEditField="hideEditField"
                                @onChange="changeTrackingNumberExpress"
                        />
                        <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                      </template>
                    </v-popover>
                  </div>
                </template>
                <template v-else>
                  <div class="">
                    <v-popover
                            class="site-tooltip"
                            offset="5"
                            placement="top-left"
                            @show="hideEditField = true"
                            @hide="hideEditField = false"
                    >
                      <span class="table-link btn-style">{{ $t('importO_addNumber.localization_value.value') }}</span>

                      <template slot="popover">
                        <div class="section-label mb-2">{{ $t('importO_trackingNumber.localization_value.value') }}
                        </div>
                        <InputEditField
                                :value="''"
                                :propId="item.id"
                                :hideEditField="hideEditField"
                                @onChange="changeTrackingNumberExpress"
                        />

                        <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                      </template>
                    </v-popover>
                  </div>
                </template>
              </div>


              <div class="docs-activity-block">



                <template v-if="FEDEX_DELIVERY_SERVICE.id === item.delivery_service_id &&
                  item.document_confirmation_payload &&
                  item.document_confirmation_payload.length > 0"
                >
                  <template v-for="(itemDocument, index) in item.document_confirmation_payload">
                    <TooltipTextHelper
                        :key="index">
                      <template slot="text">
                      <span>
                        <LabelCircle
                            :color="itemDocument['Status'] === 'SUCCESS' ? 'green' : 'gray-light'"
                            :value="getCustomerReferenceType(itemDocument['CustomerReference']).type"
                            class="mr-8 mb-2"
                        />
                      </span>
                      </template>
                      <template slot="title">
                        <div class="status-tooltip">
                          <LabelCircle
                              :color="itemDocument['Status'] === 'SUCCESS' ? 'green' : 'gray-light'"
                              :value="getCustomerReferenceType(itemDocument['CustomerReference']).type"
                              class="mr-8"/>
                          {{itemDocument['Status'] === 'SUCCESS' ? 'Success' : 'Error'}}
                          {{getCustomerReferenceType(itemDocument['CustomerReference']).value}}
                        </div>
                      </template>
                    </TooltipTextHelper>
                  </template>


<!--                  <v-popover class="site-tooltip d-flex" offset="5" placement="top-left" trigger="hover"-->
<!--                             v-for="(itemDocument, index) in item.document_confirmation_payload"-->
<!--                             :key="index"-->
<!--                  >-->
<!--                    <span>-->
<!--                      <LabelCircle-->
<!--                              :color="itemDocument['Status'] === 'SUCCESS' ? 'green' : 'gray-light'"-->
<!--                              :value="getCustomerReferenceType(itemDocument['CustomerReference']).type"-->
<!--                              class="mr-8 mb-2"-->
<!--                      />-->
<!--                    </span>-->
<!--                    <template slot="popover">-->
<!--                      <div class="status-tooltip">-->
<!--                        <LabelCircle-->
<!--                                :color="itemDocument['Status'] === 'SUCCESS' ? 'green' : 'gray-light'"-->
<!--                                :value="getCustomerReferenceType(itemDocument['CustomerReference']).type"-->
<!--                                class="mr-8"/>-->
<!--                        {{itemDocument['Status'] === 'SUCCESS' ? 'Success' : 'Error'}}-->
<!--                        {{getCustomerReferenceType(itemDocument['CustomerReference']).value}}-->
<!--                      </div>-->
<!--                    </template>-->
<!--                  </v-popover>-->
                </template>

              </div>
            </td>
            <td>
              <ValueHelper
                      :value="item.delivery_date"
                      :date="true"
                      :dateType="'DD MMM, YYYY'"
              />
            </td>
            <td v-if="isAdmin">
              <div class="d-flex align-items-center">
                <DefaultCheckbox
                    v-if="item.alarm_status !== 1"
                    class="empty-label cursor-pointer-checkbox"
                    :value="item.alarm_status === 1"
                    :disabled="true"
                    @click.native="changeAlarm(item, item.alarm_status)"
                />
                <span v-if="item.alarm_status === 1"
                      class="color-red alarm-style"
                      @click="changeAlarm(item, item.alarm_status)"
                >
                  {{$t('express_alarm.localization_value.value')}}
                </span>
              </div>
            </td>
            <td>

              <div class="d-flex position-relative mt--1" style="top: 4px;">
                <div class="mr-1" v-if="isAdmin">
                  <span v-if="item.packaging_type === FEDEX_PACKAGING_TYPES.FEDEX_PACKAGING_TYPE.value" class="brick-btn label-circle--violet">PAK</span>
                  <span v-if="item.packaging_type === FEDEX_PACKAGING_TYPES.ENVELOPE_FEDEX_PACKAGING_TYPE.value" class="brick-btn label-circle--orange">ENV</span>
                  <span v-if="item.packaging_type === FEDEX_PACKAGING_TYPES.HIMSELF_FEDEX_PACKAGING_TYPE.value" class="brick-btn label-circle--green">YOUR</span>
                </div>

                <TooltipTextHelper :fullTextWidth="100">
                  <template slot="text">
                    {{item.item_name}}
                  </template>
                  <template slot="paragraph">
                    {{item.item_name}}
                  </template>
                </TooltipTextHelper>
              </div>

            </td>
            <td>
              <ExpressManageButton
                      :item="item"
                      :availableCourierTime="availableCourierTime"
                      @removeExpress="removeExpress(item.id, index)"
                      @copyExpress="copyExpress(item.id, true)"
                      @showReferencePopup="(type) => showReferencePopup(item, type)"
                      @showBuyLabelPopup="(confirm) => showBuyLabelPopup(item.id, item.delivery_service_id, confirm)"
                      @confirmLabel="confirmLabel(item.id, item.delivery_service_id)"
                      @exportConfirmationDocument="(type) => exportConfirmationDocument(item.id, type)"
                      @exportDocsExpress="exportDocsExpress(item.id, item.delivery_service_id)"
                      @showCallingCourierPopup="showCallingCourierPopup(item)"
                      @showCourierDetailPopup="showCourierDetailPopup(item)"
                      @expressCancelCourier="expressCancelCourier(item.delivery_service_id, item.id)"
                      @expressCancelCourierPopup="expressCancelCourierPopup(item)"
                      @createLabelNovaPoshta="createLabelNovaPoshta(item)"
                      @getDeliveryPriceCDEK="getDeliveryPriceCDEK(item)"
                      @thermalPrint="(data) => $emit('thermalPrint', data)"
              />
                      <!--@exportCommercialInvoice="exportCommercialInvoice(item.id)"-->
                      <!--@barcodeCDEK="barcodeCDEK(item)"-->
                      <!--@waybillCDEK="waybillCDEK(item)"-->
            </td>
          </tr>

          </tbody>
        </table>

        <div class="table-card" v-else>
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getExpress"
                 :key="index">
              <ExpressOrderTableUserTableMobile
                      :item="item"
                      @changeTrackingNumber="changeTrackingNumberExpress"
                      @showReferencePopup="(type) => showReferencePopup(item, type)"
                      @showCallingCourierPopup="showCallingCourierPopup(item)"
                      @changeNovaPoshtaTrackingNumberExpress="changeNovaPoshtaTrackingNumberExpress"
                      @changeTrackingNumberExpress="changeTrackingNumberExpress"
                      @removeExpress="removeExpress(item.id, index)"
                      @copyExpress="copyExpress(item.id, true)"
                      @showBuyLabelPopup="(confirm) => showBuyLabelPopup(item.id, item.delivery_service_id, confirm)"
                      @confirmLabel="confirmLabel(item.id, item.delivery_service_id)"
                      @exportConfirmationDocument="(type) => exportConfirmationDocument(item.id, type)"
                      @exportDocsExpress="exportDocsExpress(item.id, item.delivery_service_id)"
                      @expressCancelCourierPopup="expressCancelCourierPopup(item)"
                      @showCourierDetailPopup="showCourierDetailPopup(item)"
                      @thermalPrint="(data) => $emit('thermalPrint', data)"
                      :selectedNow="selectedNow"
                      :dataValue="item.id"
              />
                      <!--@exportCommercialInvoice="exportCommercialInvoice(item.id)"-->
            </div>

          </div>
        </div>
      </div>


      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore
                  v-if="$store.getters.getExpressCommonList.next_page_url !== null && $store.getters.getExpress.length > 0"
                  @click.native="$emit('showMore')"
                  :count="$store.getters.getExpressCommonList.total - $store.getters.getExpressForPage * $store.getters.getExpressCommonList.current_page < $store.getters.getExpressForPage ?
                  $store.getters.getExpressCommonList.total - $store.getters.getExpressForPage * $store.getters.getExpressCommonList.current_page:
                  $store.getters.getExpressForPage"
          />
          <ExportBtn
              :customType="_.has($store.getters.getExpressCommonList, 'addition_export') ? $store.getters.getExpressCommonList.addition_export : null"
              @downloadFiles="type => $emit('downloadFiles', type)"
                  class="table-bottom-btn__right"/>
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getExpressLoading === false && $store.getters.getExpress.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>

    <CallingCourierPopup
            v-if="isModalCallingCourierPopup"
            :orderPopupData="orderPopupData"
            :availableCourierTime="availableCourierTime"
            @close="closeCallingCourierPopup"
            @reload="$emit('reload')"
            @successPickup="confirmCallCourier"
    />

    <CourierDetailPopup
        v-if="isModalCourierDetailPopup"
        :item="courierDetailData"
        @close="closeCourierDetailPopup"
    />

    <CancelCourierPopup
            v-if="isModalCancelCourierPopup"
            :id="orderId"
            :orderPopupData="orderPopupData"
            @close="closeCancelCourierPopup"
            @reload="$emit('reload')"
    />

    <BuyLabelPopup
            class="buy-label-popup"
            v-if="isModalBuyLabelPopup"
            :idCostExpressLabel="idCostExpressLabel"
            :deliveryServiceId="deliveryServiceId"
            :confirmation="isModalBuyLabelPopupConfirm"
            @close="closeBuyLabelPopup"
            @reload="$emit('reload')"
            @payForAuthorize="payForAuthorize"
    />

    <AuthorizePaymentPopup
            v-if="authorizePaymentPopup"
            :countMoney="authorizePaySum"
            :authorizePayData="authorizePayData"
            :authorizeError="authorizeError"
            :serverError="serverError"
            @close="changeAuthorizePaymentPopup(false)"
            @submitForm="submitAuthorizeInvoicePay"
    />

    <BraintreePaymentPopup
        v-if="braintreePaymentPopup"
        :amount="authorizePaySum"
        :invoicePayId="authorizeInvoiceId"
        :payData="authorizePayData"
        @submitForm="afterPay"
        @close="braintreePaymentPopup = false"
    />

    <ThankYouPopup
            v-if="thankYouPopupPopup"
            :title="'common_paySuccessTitle'"
            :text="'common_paySuccessText'"
            :linkLastText="'common_paySuccessTextLink'"
            :linkLastTextHref="$store.getters.GET_PATHS.financeMyPayments + '?type=authorize'"
            @close="changeThankYouPopupPopup(false)"
    />

    <ReferencePopup
            v-if="isModalReferencePopup"
            :referenceType="referenceType"
            :referenceNumber="referenceNumber"
            @close="closeReferencePopup"
    />

    <PayOrderPopup
            v-if="payOrderPopup"
            :orderPrice="orderPrice"
            :disableBtn="$store.getters.getNovaPoshtaBtn || $store.getters.getOrdersInternalBtn"
            @close="changePayOrderPopup(false)"
            @createLabel="createLabel(orderId, true)"
    />



  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  // import StatusIcoBtn from "../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import InputEditField from "../../../../../../UI/inputs/InputEditField/InputEditField";
  import CallingCourierPopup from "../../../../popups/CallingCourierPopup/CallingCourierPopup.vue";
  import BuyLabelPopup from "../../../../../../coreComponents/Popups/BuyLabelPopup/BuyLabelPopup.vue";
  import ReferencePopup from "../../../../popups/ReferencePopup/ReferencePopup";
  import {
    DHL_DELIVERY_SERVICE,
    EXPRESS_DELIVERY_STATUSES,
    FEDEX_DELIVERY_SERVICE, FEDEX_PACKAGING_TYPES, NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE, ORDER_TYPE_EXPRESS,
    TNT_DELIVERY_SERVICE
  } from "../../../../../../../staticData/staticVariables";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import ExpressOrderTableUserTableMobile from "./ExpressOrderTableUserTableMobile/ExpressOrderTableUserTableMobile";
  import {expressMixins} from "../../../../../../../mixins/expressMixins/expressMixin";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  import AuthorizePaymentPopup from "../../../../../../coreComponents/Popups/AuthorizePaymentPopup/AuthorizePaymentPopup";
  import ThankYouPopup from "../../../../../../coreComponents/Popups/ThankYouPopup/ThankYouPopup";
  import {paymentBlockFunc} from "../../../../../../../mixins/dashboardMixins/paymentBlockFunc";
  import {novaPoshtaOrdersTableMixins} from "../../../../../../../mixins/internalOrdersMixins/novaPoshtaOrdersMixin";
  import PayOrderPopup from "../../../../../../coreComponents/Popups/PayOrderPopup/PayOrderPopup";
  import ExpressManageButton from "./components/ExpressManageButton";
  import {
    cdekGetLabelMixin,
    cdekOrdersTableMixins
  } from "../../../../../../../mixins/internalOrdersMixins/сdekOrdersMixin";
  import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import CancelCourierPopup from "../../../../popups/CancelCourierPopup/CancelCourierPopup";
  import LabelCircle from "../../../../../../coreComponents/LabelCircle/LabelCircle";
  import TableTrackingLog from "@/components/coreComponents/TableComponents/TableTrackingLog/TableTrackingLog";
  import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";
  import CourierDetailPopup from "../../../../popups/CourierDetailPopup/CourierDetailPopup";
  import BraintreePaymentPopup from "@/components/coreComponents/Popups/BraintreePaymentPopup/BraintreePaymentPopup";


  export default {
    name: "ExpressOrderTableUserTable",

    components: {
      BraintreePaymentPopup,
      CourierDetailPopup,
      TooltipTextHelper,
      TableTrackingLog,
      LabelCircle,
      TableUserColumn,
      ExpressManageButton,
      ThankYouPopup,
      AuthorizePaymentPopup,
      ValueHelper,
      ExpressOrderTableUserTableMobile,
      NoResult,
      ShowMore,
      ExportBtn,
      DefaultCheckbox,
      LinkButton,
      // StatusIcoBtn,
      InputEditField,
      CallingCourierPopup,
      BuyLabelPopup,
      ReferencePopup,
      PayOrderPopup,
      CancelCourierPopup,
    },

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    mixins: [
      mixinDetictingMobile,
      expressMixins,
      paymentBlockFunc,
      novaPoshtaOrdersTableMixins,
      cdekGetLabelMixin,
      cdekOrdersTableMixins
    ],

    watch: {
      loadUserAuthorizedData() {
        this.checkUserCourierAvailableTime()
      },
    },

    data() {
      return {
        orderId: '',
        showFilter: false,
        isMobile: false,
        fixShowManageBar: true,
        showContent: false,
        hideEditField: false,

        DHL_DELIVERY_SERVICE: DHL_DELIVERY_SERVICE,
        FEDEX_DELIVERY_SERVICE: FEDEX_DELIVERY_SERVICE,
        TNT_DELIVERY_SERVICE: TNT_DELIVERY_SERVICE,
        NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE: NOVAPOSHTA_GLOBAL_DELIVERY_SERVICE,

        EXPRESS_DELIVERY_STATUSES: EXPRESS_DELIVERY_STATUSES,

        ORDER_TYPE_EXPRESS: ORDER_TYPE_EXPRESS,

        isModalCallingCourierPopup: false,
        isModalCancelCourierPopup: false,
        isModalBuyLabelPopup: false,
        isModalReferencePopup: false,
        isModalBuyLabelPopupConfirm: false,
        isModalCourierDetailPopup: false,

        orderPopupData: {},
        courierDetailData: {},

        idCostExpressLabel: -1,
        deliveryServiceId: -1,

        authorizePayData: {},
        authorizePaySum: -1,
        authorizeInvoiceId: -1,
        authorizePaymentPopup: false,
        braintreePaymentPopup: false,
        thankYouPopupPopup: false,
        serverError: false,
        authorizeError: false,

        payOrderPopup: false,

        referenceType: '',
        referenceNumber: '',

        availableCourierTime: null,

        FEDEX_PACKAGING_TYPES: FEDEX_PACKAGING_TYPES,
      }
    },

    mounted() {
      if(this.loadUserAuthorizedData){
        this.checkUserCourierAvailableTime()
      }
    },

    methods: {


      changeAlarm(item, status) {
        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_yes',
          no: 'common_confirmNo'
        }

        let confirm = () => {
          let data = {
            'alarm_status': status === 1 ? 0 : 1,
          }

          this.$store.dispatch('changeExpressAlarm', {id: item.id, data: data}).then(response => {
            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

                let itemExpress = this._.find(this.$store.getters.getExpress, {id: item.id})
                itemExpress['alarm_status'] = data['alarm_status']
                this.openNotify('success', 'common_notificationRecordChanged')
                // this.$emit('reload')
                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },


      checkUserCourierAvailableTime(){
        this.$store.dispatch('identifyPickupCity',
          {user_id: this.loadUserAuthorizedData.user.id}
        ).then(response => {
          this.availableCourierTime = this.getRespData(response).courier_city
        }).catch(error => this.createErrorLog(error))

      },

      createLabelNovaPoshta(item) {
        this.orderId = item.order_internal_id
        this.getDeliveryPriceFromTable(item.order_internal_id)
      },

      getDeliveryPriceCDEK(item) {
        this.orderId = item.order_internal_id
        this.getDeliveryPriceCDEKFromTable(item.order_internal_id)
      },

      // barcodeCDEK(item) {
      //   this.getCDEKProductLabel(item.order_internal_id)
      // },
      //
      // waybillCDEK(item) {
      //   this.getCDEKOrderLabel(item.order_internal_id)
      // },



      submitAuthorizeInvoicePay(data){
        this.payAuthorizeInvoice({data: data, invoiceId: this.authorizeInvoiceId}).then(status => {
          if(status){
            this.$store.dispatch('afterInvoicePayStatusUpdate', this.idCostExpressLabel).then((response => {
              if(response.data.data.status){
                let orderItem = this._.find(this.$store.getters.getExpress, {id: parseInt(this.idCostExpressLabel)})
                orderItem.status = this.EXPRESS_DELIVERY_STATUSES.WAIT_FOR_PAY_STATUS
              }
            }))
          }
          this.authorizePaymentPopup = false

          setTimeout(() => {
            this.changeThankYouPopupPopup(true)
          }, 200)
        })
      },


      afterPay(){
        this.$store.dispatch('afterInvoicePayStatusUpdate', this.idCostExpressLabel).then((response => {
          if(response.data.data.status){
            let orderItem = this._.find(this.$store.getters.getExpress, {id: parseInt(this.idCostExpressLabel)})
            orderItem.status = this.EXPRESS_DELIVERY_STATUSES.WAIT_FOR_PAY_STATUS
          }

          this.authorizePaymentPopup = false

          setTimeout(() => {
            this.changeThankYouPopupPopup(true)
          }, 200)
        }))
      },


      exportDocsExpress(id, deliveryServiceId) {
        let orderExpressType = this.getExpressTypeById(deliveryServiceId)
        this.$store.dispatch('getExportCommercialInvoice', {order_express_id: id}).then(response => {
          this.globalDownloadBase64File('application/docx', response.data.data.commercial_invoice_file, `${orderExpressType.label} docs.docx`)
        }).catch(error => this.createErrorLog(error))
      },

      // exportCommercialInvoice(id) {
      //   this.$store.dispatch('getExpressProform', {order_express_id: id}).then((response) => {
      //     this.globalOpenPdfBase64(response.data.data.document_package)
      //   })
      // },

      exportConfirmationDocument(id, type) {
        this.$store.dispatch('getExpressConfirmationDocument', {order_express_id: id, fileType: type}).then(response => {
          this.globalDownloadBase64File('application/docx', response.data.data.file, `${type[0].toUpperCase()}${type.slice(1)}.docx`)
        }).catch(error => this.createErrorLog(error))
      },

      payForAuthorize({labelNeedMoneyAmount, invoiceId, userBalance, orderAmount, paySystemCommission}) {
        this.authorizePayData = {
          userBalance,
          orderAmount,
          paySystemCommission,
        }
        this.authorizePaySum = labelNeedMoneyAmount
        this.authorizeInvoiceId = invoiceId
        this.closeBuyLabelPopup()

        if(this._.has(this.currentPermissions, this.PERMISSIONS.CUSTOMER_PAYMENT_BRAINTREE)) {
          this.changeBraintreePaymentPopup(true)
        } else {
          this.changeAuthorizePaymentPopup(true)
        }
      },

      showCourierDetailPopup(item) {
        let type = this._.find(this.navTabs, {id: item.delivery_service_id})

        if (type?.name === DHL_DELIVERY_SERVICE.name) {
          this.courierDetailData = {
            confirmationNumber: item?.pickup_data?.ConfirmationNumber,
            courierArrivalDate: item?.courier_arrival_date,
            contactPersonName: Array.isArray(item?.warehouse) !== true ? item?.warehouse?.sender_name : item?.courier_contact_name,
            contactPhone: Array.isArray(item?.warehouse) !== true ? item?.warehouse?.sender_phone : item?.courier_contact_phone,
            packageLocation: Array.isArray(item?.warehouse) !== true ? item?.warehouse?.sender_address : item?.sender_contact_address,
          }
        }
        else if (type?.name === FEDEX_DELIVERY_SERVICE.name) {
          this.courierDetailData = {
            confirmationNumber: item?.pickup_data?.PickupConfirmationNumber,
            courierArrivalDate: item?.courier_arrival_date,
            contactPersonName: item?.courier_alternative_address ? item?.courier_alternative_address?.contact_name : Array.isArray(item?.warehouse) !== true ? item?.warehouse?.sender_name : item?.sender_contact_name,
            contactPhone: item?.courier_alternative_address ? item?.courier_alternative_address?.phone : Array.isArray(item?.warehouse) !== true ? item?.warehouse?.sender_phone : item?.sender_phone_number,
            packageLocation: item?.courier_alternative_address ? item?.courier_alternative_address?.address : Array.isArray(item?.warehouse) !== true ? item?.warehouse?.sender_address : item?.sender_contact_address,
          }
        }

        if(item.courier_from_to_time !== null) {
          let courierTime = item.courier_from_to_time.split('|')
          this.courierDetailData["timeFrom"] = this.$moment(courierTime[0], 'HH:mm').format('HH:mm')
          this.courierDetailData["timeTo"] = this.$moment(courierTime[1], 'HH:mm').format('HH:mm')
        }

        this.isModalCourierDetailPopup = true

      },

      showCallingCourierPopup(item) {
        let type = this._.find(this.navTabs, {id: item.delivery_service_id})

        let senderData = item.user?.use_alternative_address === 1 ? item.user.contacts[1] : item.user.contacts[0]
        let senderWarehouseData = item.warehouse?.id ? item.warehouse : null

        this.orderPopupData = {
          id: item.id,
          name: senderWarehouseData ? senderWarehouseData?.sender_name : senderData?.user_full_name,
          phone: senderWarehouseData ? senderWarehouseData?.sender_phone : senderData?.phone,
          deliveryDate: item?.delivery_date,
          deliveryTypeName: type?.name,
          deliveryTypeId: type?.id,

          country: {
            id: senderWarehouseData ? senderWarehouseData?.country.id : senderData?.country?.id,
            label: senderWarehouseData ? senderWarehouseData?.country?.name : senderData?.country?.name,
            code_iso_2: senderWarehouseData ? senderWarehouseData?.country?.code_iso_2 : senderData?.country?.code_iso_2,
          },
          city: senderWarehouseData ? senderWarehouseData?.sender_city : senderData?.city,
          address: senderWarehouseData ? senderWarehouseData?.sender_address : senderData?.address,
          region: senderWarehouseData ? senderWarehouseData?.sender_contact?.region?.name : senderData?.region?.name,
          zip: senderWarehouseData ? senderWarehouseData?.sender_postcode : senderData?.zip,
        }

        this.isModalCallingCourierPopup = true
      },

      closeCallingCourierPopup() {
        this.orderPopupId = -1
        this.deliveryNamePopup = ''
        this.isModalCallingCourierPopup = false
      },

      closeCourierDetailPopup() {
        this.isModalCourierDetailPopup = false
      },

      expressCancelCourierPopup(item) {
        this.orderId = item.id
        let type = this._.find(this.navTabs, {id: item.delivery_service_id})
        this.orderPopupData = {
          deliveryTypeName: type.name,
        }
        this.isModalCancelCourierPopup = true
      },

      closeCancelCourierPopup() {
        this.orderId = -1
        this.isModalCancelCourierPopup = false
      },

      confirmCallCourier() {
        this.$emit('reload')
        this.isModalCallingCourierPopup = false
      },

      changeTrackingNumberExpress({value, id}) {
        this.$store.dispatch('addTrackNumExpress', {
          id: id,
          data: {tracking_number: value}
        }).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            this.openNotify('success', 'common_notificationStatusChanged')
            let express = this.$store.getters.getExpress
            this._.find(express, {id: id}).tracking_number = value
          } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            let errors = response.response.data.errors;

            setTimeout(() => {
              this.openNotify('error', {txtServer: errors['tracking_number'][0]})
            }, 200)

          } else {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }).catch(error => this.createErrorLog(error))
      },

      changeNovaPoshtaTrackingNumberExpress({value, id}) {
        this.$store.dispatch('addNewPoshtaTrackNumExpress', {
          id: id,
          data: {tracking_number: value}
        }).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            this.openNotify('success', 'common_notificationStatusChanged')
            let express = this.$store.getters.getExpress
            this._.find(express, {id: id}).new_post_tracking_number = value
          } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            let errors = response.response.data.errors;

            setTimeout(() => {
              this.openNotify('error', {txtServer: errors['tracking_number'][0]})
            }, 200)

          } else {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }).catch(error => this.createErrorLog(error))
      },

      expressCancelCourier(deliveryId, orderId){
        let type = this._.find(this.navTabs, {id: deliveryId})

        this.$store.dispatch('cancelCourier', {
          type: type.name,
          data: {
            'order_express_id': orderId,
          }
        }).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            this.openNotify('success', 'common_notificationStatusChanged')

            this.$emit('reload')

          } else if(response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            let errors = response.response.data.errors;

            // let errTxt = errors
            this.openNotify('error', {txtServer: errors})
          } else {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        })
      },

      showBuyLabelPopup(id, deliveryServiceId, confirm = false) {
        this.idCostExpressLabel = id
        this.isModalBuyLabelPopup = true
        this.isModalBuyLabelPopupConfirm = confirm
        this.deliveryServiceId = deliveryServiceId

      },



      closeBuyLabelPopup() {
        this.isModalBuyLabelPopup = false
      },

      showReferencePopup(item, type) {
        this.referenceType = type

        if(type === 'refBooking')
          this.referenceNumber = item.booking_reference_number
        else
          this.referenceNumber = item.reference_number

        this.isModalReferencePopup = true
      },

      closeReferencePopup() {
        this.referenceType = ''
        this.referenceNumber = ''
        this.isModalReferencePopup = false
      },


    },

  }
</script>

<style scoped lang="scss">

  .site-table thead th,
  .site-table tbody td{
    padding: 20px 4px;
    font-size: 13px;
  }

  .site-table td:nth-child(2),
  .site-table td:nth-child(3),
  .site-table th:nth-child(2),
  .site-table th:nth-child(3) {
    padding-left: 0;
  }

  .site-table td:nth-child(9) .table-ico {
    width: 20px;
  }

  /deep/ .site-table .manager-btn__list>.link-btn {
    margin-right: 10px;
    padding-right: 10px;
  }

  .docs-activity-block{
    display: flex;
    justify-content: flex-start !important;
    margin-top: 3px;
  }

  .site-table .ellipsis {
    max-width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    display: inline-block;
  }

  /deep/ .cursor-pointer-checkbox .default-checkbox__label{
    cursor: pointer;
  }

  /deep/ .site-table .brick-btn {
    font-size: 12px;
  }

  /deep/ .buy-label-popup .radio-default__label {
    white-space: nowrap;
  }

  .alarm-style {
    padding: 2px 4px;
    border: 1px solid #eb5757;
    border-radius: 4px;
    text-transform: uppercase;
    background-color: #eb5757;
    color: white;
    cursor: pointer;
    font-size: 12px;
  }

  @media (max-width: 1830px) {
    /deep/ .express-table .table-manage-list .manager-btn {
      width: 84px;
    }
  }

  @media (min-width: 1830px) {
    /deep/ .express-table .table-manage-list .manager-btn {
      width: 190px;
    }

    .express-table .site-table tbody td:last-child{
      padding-right: 5px;
    }
  }
</style>
